<template>
  <q-modal-view id="modalCashback" ref="modal" title="Regulamento Cashback">
    <PDFViewer
      :source="pdf"
      style="height: calc(100vh - 98px); width: 100%"
      :controls="['zoom']"
      loading-text="Carregando ..."
      rendering-text="Renderizando ..."
    />
  </q-modal-view>
</template>

<script>
import QModalView from "@/components/shared/QModalView";
import ParametrosService from "@/services/parametros";
import PDFViewer from "pdf-viewer-vue";

export default {
  name: "QModalCashback",

  components: {
    QModalView,
    PDFViewer,
  },

  data() {
    return {
      pdf: null,
    };
  },

  created() {
    this.buscaHTMLCashback();
  },

  methods: {
    open() {
      this.$refs.modal.show();
    },

    close() {
      this.$refs.modal.hide();
    },

    buscaHTMLCashback() {
      this.loading = true;
      ParametrosService.getParametro("PDF_CASHBACK")
        .then(({ data }) => {
          this.pdf = data[0].valor;
        })
        .catch(() => {
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Não foi possível realizar o download do cartão!",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.pdf-viewer__header {
  .header__preview {
    .start,
    .end {
      display: none;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
}
</style>
