<template>
  <div class="row justify-center">
    <div class="row justify-center bonus-container">
      <div
        class="col items-center justify-center q-pa-lg"
        :class="{ 'bg-dark': $q.dark.isActive, 'bg-white': !$q.dark.isActive }"
      >
        <div v-if="loading" class="row justify-center">
          <q-skeleton animation="blink" width="120px" height="30px" />
        </div>
        <p
          v-else
          class="text-h5 text-center text-weight-medium q-ma-none q-pt-sm"
        >
          Saldo Total
        </p>

        <div v-if="loading" class="row justify-center q-py-lg">
          <q-skeleton animation="blink" width="200px" height="60px" />
        </div>
        <p
          v-else
          class="row justify-center valor-bonus text-center q-ma-none q-py-xs text-primary"
        >
          <span ref="bonusCounter" />
          <span v-if="valorDisponivel === 0">R$ 0,00</span>
        </p>

        <div v-if="loading" class="row justify-center">
          <q-skeleton animation="blink" width="220px" height="20px" />
        </div>
        <div v-if="loading" class="row justify-center q-my-sm">
          <q-skeleton animation="blink" width="120px" height="30px" />
        </div>
        <div v-if="loading" class="row justify-center">
          <q-skeleton animation="blink" width="320px" height="30px" />
        </div>
        <p v-if="!loading && valorDisponivel > 0" class="text-center">
          Disponível para uso a partir do dia <br />
          <span class="text-weight-bolder data-disponivel">
            {{ dataDisponivel }}
          </span>
          <span class="q-pl-xs q-mb-lg text-h6">*</span>
        </p>
        <p
          v-if="!loading"
          @click="abrirModalCashback"
          class="text-caption text-weight-medium text-center q-ma-none cursor-pointer"
        >
          * Clique <span class="text-weight-bold text-primary">aqui</span> para
          conferir as regras para utilização
        </p>
      </div>
    </div>
    <div class="row justify-center bonus-container">
      <div
        class="col items-center justify-center q-mt-md q-pa-lg"
        :class="{ 'bg-dark': $q.dark.isActive, 'bg-white': !$q.dark.isActive }"
      >
        <q-table-skeleton v-if="loading" />
        <q-table
          v-else
          flat
          bordered
          :rows="rows"
          :columns="columns"
          row-key="DataCriacao"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="DataCriacao" :props="props">{{
                formataData(props.row.DataCriacao)
              }}</q-td>
              <q-td key="ValorResgatar" :props="props">{{
                formataValorBonusCashback(props.row.ValorResgatar)
              }}</q-td>
              <q-td key="DataValidade" :props="props">{{
                formataData(props.row.DataValidade)
              }}</q-td>
              <q-td key="Status" :props="props">
                <q-badge :color="getBadgeColor(props.row.Status)">{{
                  props.row.Status
                }}</q-badge>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
    <q-modal-cashback ref="modalCashback" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { CountUp } from "countup.js";
import QModalCashback from "../../components/shared/QModalCashback";
import QTableSkeleton from "../../components/shared/QTableSkeleton";
import CashbackService from "../../services/cashback";
import store from "@/store/index";
import MixinMessages from "@/mixins/mixinMessages";

export default {
  name: "MeuBonus",

  components: {
    QModalCashback,
    QTableSkeleton,
  },

  mixins: [MixinMessages],

  data() {
    return {
      loading: true,
      columns: [
        {
          name: "DataCriacao",
          label: "Data",
          align: "center",
          field: "DataCriacao",
        },
        {
          name: "ValorResgatar",
          label: "Valor Bônus",
          align: "center",
          field: "ValorResgatar",
        },
        {
          name: "DataValidade",
          label: "Vencimento",
          align: "center",
          field: "DataValidade",
        },
        { name: "Status", label: "Status", align: "center", field: "Status" },
      ],
      rows: [],
    };
  },

  created() {
    this.buscaCashback();
  },

  computed: {
    cashbackDisponivel() {
      return this.rows.find((row) => row.Status === "Disponível") || null;
    },

    valorDisponivel() {
      return this.cashbackDisponivel
        ? Number(this.cashbackDisponivel.ValorResgatar)
        : 0;
    },

    dataDisponivel() {
      return this.cashbackDisponivel
        ? dayjs(this.cashbackDisponivel.DataDisponivel).format("DD/MM/YYYY")
        : "-";
    },
  },

  methods: {
    async buscaCashback() {
      this.loading = true;
      const cpf = String(store.getters.getUsuarioLogado.cpf).padStart(11, "0");
      try {
        const { data } = await CashbackService.get({ cpf, pageSize: 100 });
        this.rows = data.cashback;
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.valorDisponivel > 0) {
              this.startCountUp();
            }
          }, 1);
        });
      } catch (error) {
        console.error(error);
        this.errorMessage({
          title: "Ops, algo de errado aconteceu!",
          message: "Não foi possível buscar pelos seus bônus",
          error,
        });
        this.$router.back();
      } finally {
        this.loading = false;
      }
    },

    formataData(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },

    formataValorBonusCashback(valor) {
      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },

    getBadgeColor(status) {
      const statusColors = {
        Disponível: "blue",
        Utilizado: "green",
        Cancelado: "red",
        Expirado: "amber",
        Indisponível: "grey",
        Indefinido: "grey",
        Bloqueado: "grey",
      };
      return statusColors[status];
    },

    abrirModalCashback() {
      this.$refs.modalCashback.open();
    },

    startCountUp() {
      const bonusCounter = new CountUp(
        this.$refs.bonusCounter,
        this.valorDisponivel,
        {
          startVal: 0,
          decimalPlaces: 2,
          duration: 1.5,
          separator: ".",
          decimal: ",",
          prefix: "R$ ",
        }
      );
      if (!bonusCounter.error) {
        bonusCounter.start();
      } else {
        console.error(bonusCounter.error);
      }
    },
  },
};
</script>

<style>
.bonus-container {
  width: 100%;
  max-width: 1200px;
}
.data-disponivel {
  font-size: 18px;
}
.valor-bonus {
  font-size: 48px;
  font-weight: 600;
}
th,
td {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
</style>
